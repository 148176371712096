@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins/_breakpoints";

$color-1: #425B76;
$color-2: #EE7844;
$color-3: #8E9DAD;
$color-4: #E6F5F9;
$color-warning: #EF4848;
$font-family-sans-serif: "Roboto" !default;
$font-family-base: "Roboto" !default;
$headings-font-family: "Montserrat" !default;
$side-menu-width: 8em;
$top-menu-height: 6em;
