@import 'variables';
@import '../node_modules/bootstrap/scss/bootstrap';
@import '~ngx-toastr/toastr';

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-Medium"),
  url(assets/fonts/Montserrat-Medium.ttf);
  font-weight: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-Light"),
  url(assets/fonts/Montserrat-Light.ttf);
  font-weight: 100;
}
@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat-Bold"),
  url(assets/fonts/Montserrat-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: 'Roboto';
  src: local("Roboto-Regular"),
  url(assets/fonts/Roboto-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local("Roboto-Bold"),
  url(assets/fonts/Roboto-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: 'Roboto';
  src: local("Roboto-Light"),
  url(assets/fonts/Roboto-Light.ttf);
  font-weight: lighter;
}

body, html {
  font-family: $font-family-base;
  font-size: 12px;
  padding: 0;
  margin: 0 auto;
}
h1, h2, h3, h4 {
  font-family: $headings-font-family;
  color: $color-1;
}
h1, h2 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.color-1 {
  color: $color-1;
}
.color-2 {
  color: $color-2;
}
.color-3 {
  color: $color-3;
}
.color-4 {
  color: $color-4;
}
.loader {
  width: 2em;
  padding: 0 0.4em;
}
.error {
  color: red;
  font-size: 0.9em;
}
.form-group label {
  color: $color-1;
  font-weight: bold;
}
.link-2 {
  color: $color-2;
  cursor: pointer;
  &:hover {
    color: darken($color-2, 20%);
  }
}
.page-content {
  color: $color-1;
}
.autocomplete {
  max-height: 30em;
  overflow-y: scroll;
  .option {
    padding: 0.4em;
    border-top: 1px solid $color-4;
    padding-left: 1em;
    cursor: pointer;
    &:last-child {
      border-bottom: 1px solid $color-4;
    }
    &:hover {
      font-weight: bold;
    }
  }

}
.btn.btn-slick {
  background: $color-2;
  color: white;
  font-size: 1.4em;
  font-weight: 200;
  border-radius: 0;
  padding: 0.8em 2em;
  border: 2px solid transparent;
  &.active, &:hover {
    border: 2px inset darken($color-2, 20%);
  }
  &.light {
    background: $color-4;
    color: $color-1;
    &.active, &:hover {
      border: 2px inset darken($color-4, 20%);
    }
  }
  &.dark {
    background: $color-1;
    color: $color-4;
    &.active, &:hover {
      border: 2px inset lighten($color-1, 10%);
    }
  }
  &.warning {
    background: $color-warning;
  }
  &.block {
    display: block;
    width: 100%;
  }
  &.small {
    font-size: 1em;
    padding: 0.2em 0.4em;
  }
}
input.ng-invalid.ng-dirty {
  box-shadow: inset 0 0 4px 0 rgba(255,0,85,1);
}
.bg-cover {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
@include media-breakpoint-between(xs, sm) {
  body, html {
    font-size: 16px;
  }
}
@include media-breakpoint-between(sm, lg) {
  body, html {
    font-size: 14px;
  }
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
